<!-- eslint-disable -->
<template>
  <div class="page_box">
    <sb-headline :title="'保险线索'"></sb-headline>

    <div class="billboards">
      <div id="dashboard" style="height: 300px;width: 100%"></div>
    </div>
    <my-table ref="list" :data="listData" @page="pageHandler" :listLoading="listLoading">
      <!-- 表格数据搜索 -->
      <template #header="params">
        <div class="search_box">
          <el-form :inline="true" :model="searchData" label-position="top">

            <el-form-item>
              <el-input v-model="searchData.customer" placeholder="客户名称" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="searchData.mobile" placeholder="手机号" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="searchData.licensePlate" placeholder="车牌" clearable></el-input>
            </el-form-item>
            <el-form-item>
              <el-select v-model="searchData.state" placeholder="线索状态" clearable>
                <el-option label="待跟进" value="0"></el-option>
                <el-option label="已跟进" value="1"></el-option>
                <el-option label="已成交" value="2"></el-option>
                <el-option label="战败" value="3"></el-option>
                <el-option label="无效" value="4"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select v-model="searchData.firstChannel" clearable>
                <el-option :label="item.dataValue" :value="item.dataValue" v-for="(item,index) in bxClueFirstChannels" :key="index"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select v-model="searchData.secondChannel" clearable>
                <el-option :label="item.dataValue" :value="item.dataValue"  v-for="(item,index) in bxClueSecondChannels" :key="index"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-date-picker
                v-model="searchData.expireDate"
                type="date"
                value-format="yyyy-MM"
                format="yyyy-MM"
                clearable
                placeholder="保险到期日期">
              </el-date-picker>
            </el-form-item>
          </el-form>
          <el-row :gutter="10">
            <el-col :span="6" :offset="18">
              <el-button @click="resetSearchData">重置</el-button>
              <el-button @click="pageHandler(params, 1)" type="primary">查询</el-button>
              <el-button type="primary" @click="addClueFn">新增</el-button>
            </el-col>
          </el-row>
        </div>
      </template>

      <!-- 表格顶部操作 -->
      <template #navBar>
        <div class="table_top_box">
          <div>共{{ customerTotal }}条线索</div>
        </div>
      </template>

      <span v-for="(item, index) in columnData" :key="index">
        <el-table-column :label="item.label" :prop="item.prop" :min-width="item.width" v-if="item.prop == 'clueId'"
                         fixed="left">
          <template slot-scope="scope">
            {{ scope.row.clueId }}
          </template>
        </el-table-column>

        <el-table-column :label="item.label" :prop="item.prop" :min-width="item.width" v-else-if="item.prop == 'state'">
          <template slot-scope="scope">
            {{ scope.row.state | clueStatusFormatter }}
          </template>
        </el-table-column>

        <el-table-column :label="item.label" :prop="item.prop" :min-width="item.width" v-else-if="item.prop == 'terminalSource'">
          <template slot-scope="scope">
            {{ scope.row.terminalSource | terminalSourceFormatter }}
          </template>
        </el-table-column>

        <el-table-column v-else :label="item.label" :prop="item.prop" :min-width="item.width">
          <template slot-scope="scope">
            <div v-if="scope.row[item.prop] || scope.row[item.prop] === 0">{{ scope.row[item.prop] }}</div>
            <div v-else>--</div>
          </template>
        </el-table-column>
      </span>
      <el-table-column label="操作" fixed="right" prop="" width="120">
        <template slot-scope="scope">
          <el-link type="primary" @click="openViewFn(scope.row)">查看</el-link>
        </template>
      </el-table-column>
    </my-table>

    <el-dialog title="新增线索" :visible.sync="addDialogFormVisible" label-position="left" width="400">
      <el-form :model="addClueForm" :rules="addClueFormRules" ref="addClueForm" inline label-width="120px" label-position="right">
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="客户名称" prop="customer">
              <el-input v-model="addClueForm.customer" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="手机号" prop="mobile">
              <el-input v-model="addClueForm.mobile" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="车牌" prop="licensePlate">
              <el-input v-model="addClueForm.licensePlate" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="保险到期日期" prop="expireDate">
              <el-date-picker
                v-model="addClueForm.expireDate"
                type="date"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                clearable
                placeholder="保险到期日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row gutter="24">
          <el-col :span="12">
            <el-form-item label="二级来源" prop="secondChannel">
              <el-select v-model="addClueForm.secondChannel" clearable>
                <el-option :label="item.dataValue" :value="item.dataValue" v-for="(item,index) in bxClueSecondChannels" :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="一级来源" prop="firstChannel" >
              <el-select v-model="addClueForm.firstChannel" clearable>
                <el-option :label="item.dataValue" :value="item.dataValue" v-for="(item,index) in bxClueFirstChannels" :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitAddClueForm('addClueForm')">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="跟进记录" :visible.sync="followDialogVisible" width="30%" center>
      <el-form :model="followClueForm" :rules="followClueFormRules" ref="followClueForm" label-width="80px">
        <el-form-item label="跟进方式" prop="followWay">
          <el-select v-model="followClueForm.followWay" clearable>
            <el-option :label="item.dataValue" :value="item.dataValue" v-for="(item,index) in bxClueFollowWays" :key="index"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="跟进内容" prop="followRemark">
          <el-input  type="textarea" :rows="4" v-model="followClueForm.followRemark" placeholder="跟进内容" autocomplete="off" clearable></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="followDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doFollow('followClueForm')">确 定</el-button>
      </span>
    </el-dialog>



    <el-dialog title="战败" :visible.sync="defeatDialogVisible" width="30%" center>
      <el-form :model="defeatClueForm" :rules="defeatClueFormRules" ref="defeatClueForm" label-width="80px">
        <el-form-item label="战败原因" prop="defeatReasons">
          <el-input  type="textarea" :rows="4" v-model="defeatClueForm.defeatReasons" placeholder="战败原因" autocomplete="off" clearable></el-input>
        </el-form-item>

        <el-form-item label="客户有效性标记" prop="isEffective" label-width="200">
          <el-select v-model="defeatClueForm.isEffective" clearable>
            <el-option label="无效" :value="0"></el-option>
            <el-option label="有效" :value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="defeatDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doDefeat('defeatClueForm')">确 定</el-button>
      </span>
    </el-dialog>


    <el-dialog title="成交" :visible.sync="dealDialogVisible" width="30%" center>
      <el-form :model="dealClueForm" :rules="dealClueFormRules" ref="dealClueForm" label-width="120px">
        <el-form-item label="报价单号" prop="orderId">
          <el-input v-model="dealClueForm.orderId" placeholder="报价单号" autocomplete="off" clearable></el-input>
        </el-form-item>

        <el-form-item label="备注" prop="dealRemark">
          <el-input  type="textarea" :rows="2" v-model="dealClueForm.dealRemark" placeholder="备注" autocomplete="off" clearable></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dealDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doDeal('dealClueForm')">确 定</el-button>
      </span>
    </el-dialog>



    <el-drawer :visible.sync="userInfoDrawer" :with-header="false" size="50%">
      <el-container class="container_box">
        <el-container>
          <el-header class="container_box_head" height="auto">
            <div style="font-size: 16px;font-weight: 500;line-height: 40px;">线索信息</div>
            <el-row>
              <el-col :span="8">
                <span class="label">线索id：</span>
                <span class="value">{{ detailData.clueId }}</span>
              </el-col>
              <el-col :span="8">
                <span class="label">客户名称：</span>
                <span class="value">{{ emptyToLine(detailData.customer) }}</span>
              </el-col>
              <el-col :span="8" style="display: flex;align-items: center;">
                <span class="label">客户手机号：</span>
                <span class="value">{{ detailData.mobile }}</span>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8">
                <span class="label">车牌：</span>
                <span class="value">{{ detailData.licensePlate }}</span>
              </el-col>
              <el-col :span="8">
                <span class="label">一级来源：</span>
                <span class="value">{{ detailData.firstChannel }}</span>
              </el-col>
              <el-col :span="8" style="display: flex;align-items: center;">
                <span class="label">二级来源：</span>
                <span class="value">{{ emptyToLine(detailData.secondChannel) }}</span>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8">
                <span class="label">保险到期日期：</span>
                <span class="value">{{ emptyToLine(detailData.expireDate) }}</span>
              </el-col>
              <el-col :span="8" style="display: flex;align-items: center;">
                <span class="label">创建时间：</span>
                <span class="value">{{ emptyToLine(detailData.ctime) }}</span>
              </el-col>

              <el-col :span="8">
                <span class="label">订单号：</span>
                <span class="value">
                    <router-link :to="`/insurance/insuranceQuotation/view/${detailData.orderId}`" target="_blank">
                      <span class="global-table-link-style">
                        {{ emptyToLine(detailData.orderId) }}
                      </span>
                    </router-link>
                </span>
              </el-col>
            </el-row>

            <div style="float: right">
              <div style="display: flex;flex-direction:column;align-items: center">
                <div><span style="font-size: 18px;font-weight: 500">{{ detailData.state | clueStatusFormatter}}</span></div>
                <div style="margin: 10px 10px">
                  <el-button type="primary" v-if="detailData.state ===0 || detailData.state === 1" @click="openFollowDialogVisible">记录</el-button>
                  <el-button type="primary" v-if="detailData.state ===0 || detailData.state === 1" @click="openDefeatDialogVisible">战败</el-button>
                  <el-button type="primary" v-if="detailData.state === 1" @click="openDealDialogVisible">成交</el-button>
                </div>
              </div>
            </div>
          </el-header>

          <el-main class="container_box_main">
            <el-tabs class="container_box_main_tabs" v-model="selectMainTab" type="card"
                     @tab-click="maintabHandleClick">
              <el-tab-pane label="线索日志" name="1"></el-tab-pane>
            </el-tabs>
            <div class="container_box_main_content" v-if="selectMainTab==1">
            </div>

            <div class="client_itinerary" @scroll="clientItineraryScroll" v-if="selectMainTab==1"
                 v-loading="detailListLoading">
              <span v-if="clientItineraryList.length>0">
                <el-timeline v-for="(item, index) in clientItineraryList" :key="index">
                  <el-timeline-item :timestamp="index == 0 ? '' : ''" placement="top">
                    <div slot="dot"
                         :class="index == 0 ? 'client_itinerary_dot client_itinerary_dot_first' : 'client_itinerary_dot'">
                      <div>{{ item.happenTime }}</div>
                      <div v-if="index == 0" class="client_itinerary_dot_circle">
                        <div class="circle"></div>
                      </div>
                    </div>
                    <el-card style="width: 90%;">
                      <div class="client_itinerary_title">
                        <div>{{ item.wayDesc }}</div>
                      </div>
                      <div class="client_itinerary_dot_html" v-html="resetTemplateFn(item)"></div>
                      <div class="client_itinerary_dot_operator">
                        <img
                          :src="item.operatorAvatar || defaultImage"
                          alt="">
                        <div>{{ item.operator }} 提交于 {{item.ctime}}</div>
                        <div>操作</div>
                      </div>
                    </el-card>
                  </el-timeline-item>
                </el-timeline>
              </span>
              <el-empty v-if="clientItineraryList.length<1&&!detailListLoading"
                        :image-size="200"></el-empty>
            </div>
          </el-main>
        </el-container>
      </el-container>
    </el-drawer>
  </div>
</template>

<script>
/* eslint-disable */
import Template from "@/views/dashboard/template.vue";
import {
  emptyToLine
} from "@/utils/common";
import row from "element-ui/packages/row";
import * as echarts from 'echarts';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: { Template },
  filters: {
    clueStatusFormatter(clueStatus) {
      switch (clueStatus) {
        case 0:
          return "待跟进";
        case 1:
          return "已跟进";
        case 2:
          return "已成交";
        case 3:
          return "战败";
        case 4:
          return "无效";
      }
      return "--";
    },
    terminalSourceFormatter(val){
      switch (val){
        case 0:
          return "系统";
        case 1:
          return "Android";
        case 2:
          return "Ios";
        case 3:
          return "微信";
        case 4:
          return "门店通";
        default:
          return "-";
      }
    },
  },
  data() {
    //这里存放数据
    return {
      listData: [], //列表数据
      listLoading: false, //列表loading
      detailListLoading: false, //详情列表loading
      tabList: [],  //标签数据
      selectMainTab: "1",
      addDialogFormVisible: false,
      defaultImage: 'https://taian-oss.dasmaster.com/wechat/default_head_admin_table.png',
      addClueForm:{
        customer: null,
        mobile: null,
        licensePlate: null,
        firstChannel: null,
        secondChannel: null,
        orgName: null,
        expireDate: null,
      },
      followClueForm:{
        clueId: null,
        followWay: '',
        followRemark: '',
      },
      dealClueForm:{
        clueId: null,
        orderId: '',
        dealRemark: ''
      },
      defeatClueForm:{
        clueId: null,
        defeatReasons: '',
        isEffective: 1
      },
      defeatDialogVisible: false,
      // 搜索数据
      searchData: {
        mobile: null,
        customer: null,
        licensePlate: null,
        firstChannel: null,
        secondChannel: null,
        orgName: null,
        expireDate: null,
        state: null
      },
      followDialogVisible: false,
      customerTotal: "",
      dealDialogVisible: false,
      // 表格column数据
      columnData: [
        { label: "线索id", width: 180, prop: "clueId" },
        { label: "客户名称", width: 120, prop: "customer" },
        { label: "手机号", width: 120, prop: "mobile" },
        { label: "车牌", width: 100, prop: "licensePlate" },
        { label: "保险到期日期", width: 120, prop: "expireDate" },
        { label: "终端来源", width: 120, prop: "terminalSource" },
        { label: "一级来源", width: 180, prop: "firstChannel" },
        { label: "二级来源", width: 180, prop: "secondChannel" },
        { label: "线索状态", width: 120, prop: "state" },
        { label: "创建人", width: 120, prop: "creator" },
        { label: "创建时间", width: 180, prop: "ctime" },
        { label: "修改人", width: 120, prop: "updator" },
        { label: "修改时间", width: 180, prop: "mtime" },
      ],
      userInfoDrawer: false,
      detailData: {},
      detailInfoColumn: [
        { key: "clueId", value: "", label: "线索id" },
        { key: "customer", value: "", label: "客户名称" },
        { key: "mobile", value: "", label: "手机号" },
        { key: "licensePlate", value: "", label: "车牌" },
        { key: "orgName", value: "", label: "参保保司名称" },
        { key: "expireDate", value: "", label: "保险到期日期" },
        { key: "firstChannel", value: "", label: "一级来源" },
        { key: "secondChannel", value: "", label: "二级来源" },
        { key: "state", value: "", label: "线索状态" },
        { key: "creator", value: "", label: "创建人" },
        { key: "ctime", value: "", label: "创建时间" }
      ],
      clientItineraryList: [] ,
      addClueFormRules:{
        customer: [
          { required: true, message: '请输入客户名称', trigger: 'blur' }
        ],
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
        ],

        licensePlate: [
          { required: true, message: '请输入车牌', trigger: 'blur' }
        ],

        firstChannel: [
          { required: true, message: '请选择一级来源', trigger: 'change' }
        ],

        secondChannel: [
          { required: true, message: '请选择二级来源', trigger: 'change' }
        ],

        orgName: [
          { required: true, message: '请输入参保保司名称', trigger: 'blur' }
        ],

        expireDate: [
          { required: true, message: '请选择保险到期日期', trigger: 'change' },
        ]
      },
      followClueFormRules:{
        followWay: [
          { required: true, message: '请选择跟进方式', trigger: 'change' }
        ],
        followRemark: [
          { required: true, message: '请输入跟进内容', trigger: 'blur' }
        ]
      },
      defeatClueFormRules:{
        defeatReasons: [
          { required: true, message: '请输入战败原因', trigger: 'blur' }
        ]
      },
      dealClueFormRules:{
        orderId: [
          { required: true, message: '请输入订单号', trigger: 'blur' }
        ],
        dealRemark: [
          { required: true, message: '请输入成交内容', trigger: 'blur' }
        ]
      },
      bxClueFirstChannels:[],
      bxClueSecondChannels:[],
      bxClueFollowWays:[],
    };
  },
  //监听属性 类似于data概念
  computed: {
    row() {
      return row;
    }
  },
  created() {
    this.getBxClueSecondChannels();
    this.getBxClueFirstChannels();
    this.getBxClueFollowWays();
  },
  //监控data中的数据变化
  watch: {},
  mounted() {
    this.initEcharts();
  },
  //方法集合
  methods: {
    emptyToLine,
    // 页面列表翻页
    pageHandler(params, num) {
      // 获取用户列表
      this.getClientList(params, num ? num : params.pageNum, params.pageSize);
    },
    // 重置搜索数据
    resetSearchData(e) {
      this.searchData = {
        mobile: null,
        customer: null,
        licensePlate: null,
        firstChannel: null,
        secondChannel: null,
        orgName: null,
        expireDate: null,
        state: null
      };
    },
    // 重构模版字符串
    resetTemplateFn(ele) {
      return ele.extra;
    },
    openFollowDialogVisible(){
      this.followDialogVisible = true;
      this.followClueForm.clueId = this.detailData.clueId;
    },

    openDefeatDialogVisible(){
      this.defeatDialogVisible = true;
      this.defeatClueForm.clueId = this.detailData.clueId;
    },

    doFollow(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store.dispatch("bxClue/followedBxClue", this.followClueForm).then(res => {
            if (res.code == 0) {
              this.$message.success('成功');
              this.followDialogVisible = false;
              this.getClientDetail(this.detailData.clueId)
              this.getLoggers(this.detailData.clueId)
              this.itineraryPageNum = 1;
              this.itineraryPage = 0;
              this.clientItineraryList = [];
              this.followClueForm = {}
              return;
            }
            this.$message.error(res.msg);
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    doDeal(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store.dispatch("bxClue/dealBxClue", this.dealClueForm).then(res => {
            if (res.code == 0) {
              this.$message.success('成功');
              this.dealDialogVisible = false;
              this.getClientDetail(this.detailData.clueId)
              this.itineraryPageNum = 1;
              this.itineraryPage = 0;
              this.clientItineraryList = [];
              this.getLoggers(this.detailData.clueId)
              this.dealClueForm = {}
              return;
            }
            this.$message.error(res.msg);
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    doDefeat(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store.dispatch("bxClue/defeatBxClue", this.defeatClueForm).then(res => {
            if (res.code == 0) {
              this.$message.success('成功');
              this.defeatDialogVisible = false;
              this.getClientDetail(this.detailData.clueId)
              this.itineraryPageNum = 1;
              this.itineraryPage = 0;
              this.clientItineraryList = [];
              this.getLoggers(this.detailData.clueId)
              this.defeatClueForm = {}
              return;
            }
            this.$message.error(res.msg);
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    // 点击展开详细信息
    openViewFn(row) {
      this.selectTab = "1";
      this.selectMainTab = "1";
      this.groupBtnData = "0";
      this.userInfoDrawer = true;
      this.itineraryPageNum = 1;
      this.itineraryPage = 0;
      this.clientItineraryList = [];
      this.getClientDetail(row.clueId || "");

      this.getLoggers(row.clueId || "");
    },
    submitAddClueForm(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store.dispatch("bxClue/addNewBxClue", this.addClueForm).then(res => {
            if (res.code == 0) {
              this.$message.success('添加成功');
              this.addDialogFormVisible = false;
              this.$router.go(0);
              return;
            }
            this.$message.error(res.msg);
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // tab点击
    maintabHandleClick(e) {
      this.selectMainTab = e.name;
      switch (e.name) {
        case "1":
          this.itineraryPageNum = 1;
          this.itineraryPage = 0;
          this.clientItineraryList = [];
          this.getLoggers();
          break;
        default:
          break;
      }
    },

    addClueFn(){
      this.addDialogFormVisible =true;
    },
    openDealDialogVisible(){
      this.dealClueForm.clueId = this.detailData.clueId;
      this.dealDialogVisible = true;
    },
    // 日志滚动监听
    clientItineraryScroll(e) {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      const isBottom = scrollTop + clientHeight >= scrollHeight - 5;
      let itineraryPage = this.itineraryPage;
      let itineraryPageNum = this.itineraryPageNum;
      console.log(isBottom)
      if (isBottom) {
        itineraryPageNum++;
        console.log(itineraryPageNum ,itineraryPage)
        if (itineraryPageNum <= itineraryPage) {
          this.itineraryPageNum = itineraryPageNum;
          this.getLoggers();
        }
      }
    },

    async getBxClueFirstChannels(){
      await this.$store.dispatch('base/getDictionaryList',"bx_clue_first_channel").then(res => {
        console.info(res)
        this.bxClueFirstChannels = res || []
      })
    },

    async getBxClueSecondChannels(){
      await this.$store.dispatch('base/getDictionaryList',"bx_clue_second_channel").then(res => {
        this.bxClueSecondChannels =  res || []
      })
    },

    async getBxClueFollowWays(){
      await this.$store.dispatch('base/getDictionaryList',"bx_clue_follow_way").then(res => {
        this.bxClueFollowWays =  res || []
      })
    },

    initEcharts(){
      this.$store.dispatch('bxClue/getClueStatisticsDashboard',{}).then(res => {
        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementById('dashboard'));
        // 绘制图表
        myChart.setOption( {
          color:['#fc8452'],
          title: {
            text: '线索数自然月统计'
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: []
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: true,
            data: res.data.days
          },
          yAxis: {
            type: 'value',
          },
          series: [
            {
              name: '线索数',
              type: 'line',
              stack: 'Total',
              data: res.data.dimensions
            },
          ]
        });
      })
    },
    getClientList(params, pageNum, pageSize) {
      this.listLoading = true;
      this.$store.dispatch("bxClue/getClientList", {
        ...this.searchData,
        pageNum,
        pageSize
      }).then(res => {
        if (res.code == 0) {
          this.listData = res.data.list;
          params.render(res.data.total);
          this.customerTotal = res.data.total;
        }
        this.listLoading = false;
      });
    },

    getClientDetail(clueId) {
      this.listLoading = true;
      this.$store.dispatch("bxClue/getClientDetail", {
        clueId: clueId
      }).then(res => {
        if (res.code == 0) {
          this.detailData = res.data;
        }
        this.listLoading = false;
      });
    },

    getLoggers(clueId = this.detailData.clueId) {
      this.detailListLoading = true;
      this.$store.dispatch("bxClue/getLoggerPageList", {
        clueId,
        pageNum: this.itineraryPageNum,
        pageSize: 10
      }).then(res => {
        if (res.code == 0) {

          res.data.list.forEach(item => {
            item.extra = item.extra
              .replaceAll("<!n*>", "</br>")
              .replaceAll("<!]*>", "&nbsp;:&nbsp;&nbsp;&nbsp;");
          });
          this.clientItineraryList=  this.clientItineraryList.concat(res.data.list)
          this.itineraryPage = (res.data.total % 10) +1;
        }
        this.detailListLoading = false;
      });
    }
  },
};
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
::v-deep .el-drawer {
  min-width: 1100px !important;
}

.page_box {
  padding: 0 20px;

  .search_box {
    background: #fff;
    padding: 20px;
    margin-top: 20px;
  }


  .table_top_box {
    background: #fff;
    display: flex;
    align-items: center;
    padding: 15px 20px;
    margin-bottom: -20px;
    box-sizing: border-box;
  }

  .container_box {
    background: #f1f5f7;
    height: 100%;
    padding: 25px;
    box-sizing: border-box;

    .container_box_head {
      .label {
        color: #74788d;
        line-height: 30px;
        font-size: 13px;
      }

      .value {
        color: #262626;
        font-size: 13px;
        font-weight: 400;
        line-height: 30px;
      }

      background: #fff;
      margin-bottom: 25px;
      box-shadow: 4px 6px 20px 0 rgba(0, 0, 0, .02);
      padding: 20px;
      box-sizing: border-box;
      height: auto;
    }

    .container_box_main {
      background: #fff;
      box-shadow: 4px 6px 20px 0 rgba(0, 0, 0, .02);
      padding: 0 !important;

      .container_box_main_tabs {
        margin-left: -1px;
      }

      .container_box_main_content {
        padding: 0 20px 20px 20px;
        box-sizing: border-box;
      }

      .client_itinerary {
        padding: 10px 50px;
        box-sizing: border-box;
        max-height: 520px;
        overflow-y: auto;

        .client_itinerary_title {
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          > div:nth-child(2) {
            font-size: 13px;
            color: #1883ff;
            border: 1px solid #1883ff;
            border-radius: 3px;
            box-sizing: border-box;
            padding: 0 5px;
          }
        }

        .client_itinerary_dot {
          margin-left: -19px;
          margin-top: 8px;
          background: #fff;
          padding: 10px 0;
          text-align: center;
          font-size: 12px;
          color: #74788d;
          white-space: pre-line;

          .client_itinerary_dot_icon {
            width: 40px;
            height: 40px;
            display: block;
            margin-bottom: 5px;
          }

          .client_itinerary_dot_circle {
            width: 11px;
            height: 11px;
            padding-top: 30px;
            position: absolute;
            top: -30px;
            left: -5px;
            background: #fff;

            .circle {
              width: 11px;
              height: 11px;
              border-radius: 100%;
              border: 1px solid #adb5bd;
              background: #f8f9fa;
              box-sizing: border-box;
            }
          }
        }

        .client_itinerary_dot_first {
          margin-top: 23px !important;
        }

        .client_itinerary_dot_html {
          white-space: pre-wrap !important;
          background: #f8f9fa;
          font-size: 13px;
          line-height: 20px;
          padding: 12px;
          box-sizing: border-box;
          margin: 10px 0;
          color: #505d69;
        }

        .client_itinerary_dot_operator {
          display: flex;
          align-items: center;
          font-size: 13px;

          img {
            width: 28px;
            height: 28px;
            display: block;
            border-radius: 100%;
            margin-right: 10px;
          }

          > div:nth-child(2) {
            margin-right: 20px;
            color: #505d69;
          }

          > div:nth-child(3) {
            color: #adb5bd;
          }
        }
      }
    }
  }
}

::v-deep .el-dialog__body {
  padding-top: 0 !important;
}

::v-deep .el-descriptions__header {
  margin: 10px 0px !important;
}

::v-deep .el-descriptions__title {
  font-size: 13px !important;
}

::v-deep .el-descriptions__body {
  padding: 0 20px !important;
}

::v-deep .el-timeline-item__tail {
  border-left: 1px solid #dcdee0 !important;
  left: 0 !important;
}

::v-deep .el-timeline .el-timeline-item:last-child .el-timeline-item__tail {
  display: initial !important;
}

::v-deep .el-timeline:last-child .el-timeline-item:last-child .el-timeline-item__tail {
  display: none !important;
}

::v-deep .el-timeline {
  margin-bottom: 30px !important;
}

::v-deep .el-timeline-item__timestamp {
  font-size: 16px !important;
  color: #505d69 !important;
  font-weight: bold;
  margin-bottom: 15px;
}

::v-deep .el-timeline-item__wrapper {
  padding-left: 50px !important;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: none;
  border-radius: 0;
  box-sizing: border-box;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item {
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 3px solid transparent;
  box-sizing: border-box;
}

::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border-left: 1px solid #dcdee0;
  border-right: 1px solid #dcdee0;
  border-top: 3px solid #2278f5;
  box-sizing: border-box;
}

::v-deep .el-input-group__prepend {
  background-color: #fff !important;
}

::v-deep .el-table__header thead tr th:first-of-type {
  padding-left: 0;
}

.el-select {
  width: 110px !important;
}

.billboards{
  background: #fff;
}
</style>
